import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contacto</h2>
                  <p>
                    Complete el formulario a continuación para enviarnos un
                    correo electrónico y nosotros nos comunicaremos con usted lo
                    antes posible.
                  </p>
                </div>
                <a
                  href="https://forms.gle/ZrfhQ9DUEXJTCSPt9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-custom btn-lg"
                >
                  Enviar Mensaje
                </a>{" "}
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Información de Contacto</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Dirección
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefono
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <p>Síguenos en nuestras redes sociales!</p>
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.linkedin : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.youtube : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>&copy; 2021 Cygnus Consulting, All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
