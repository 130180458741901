import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Productos</h2>
            <p>
              Presentamos algunas de las soluciones que extienden la
              funcionalidad de SAP Business One.
            </p>
            <a
              href={this.props.data ? this.props.data.whastapp2 : ""}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-custom btn-lg page-scroll"
            >
              Solicita una demostración
            </a>{" "}
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="docs/Brochure - Facturación Electrónica.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="clic para ver brochure."
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Facturación Electrónica</h4>
                      </div>
                      <img
                        src="img/portfolio/01-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="docs/Brochure - Pagos Masivos.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="clic para ver brochure."
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Pago Masivo</h4>
                      </div>
                      <img
                        src="img/portfolio/02-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="docs/Brochure - Rendición de Gastos.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="clic para ver brochure."
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Rendición de Gastos</h4>
                      </div>
                      <img
                        src="img/portfolio/03-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="docs/Brochure - Detracciones.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="clic para ver brochure."
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Detracciones</h4>
                      </div>
                      <img
                        src="img/portfolio/04-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="docs/Brochure - Letras por Cobrar.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="clic para ver brochure."
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Letras Clientes</h4>
                      </div>
                      <img
                        src="img/portfolio/05-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="docs/Brochure - Letras por Pagar.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="clic para ver brochure."
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Letras Proveedores</h4>
                      </div>
                      <img
                        src="img/portfolio/06-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-title">
            <h2> </h2>
            <p> </p>
            <a
              href={this.props.data ? this.props.data.whastapp2 : ""}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-custom btn-lg page-scroll"
            >
              Solicita una demostración
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
